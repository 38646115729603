@import '../variables';

.main-wrapper {

    .row {

        p.big-p {
            font-family: $body-font;
            font-weight: 700;
            font-size: 2em;
            
            @media screen and (max-width: 576px) {
                font-size: 3em;
                margin-bottom: .5em;
            }
        }
    
        .home-text-container{ 

            .heading-text {
                text-transform: uppercase;
                font-size: 1.1em;
                font-family: $home-title-font;
                h1, h2 { font-family: $home-title-font; }
                > span { display: block; }

                @media screen and (min-width: 1024px) {
                    font-size: 1.7em;
                }
                @media screen and (min-width: 1280px) {
                    font-size: 2.0em;
                }

                p { 
                    font-family: $body-font;
                    font-weight: 700;
                    text-transform: none;
                    padding: 1em 0;
                }

                .unlock-the-secret {
                    font-size: 1em;
                }

                .prize-money {
                    line-height: 0.9; 
                    font-size: 3.5em;
                    margin: 0; 
                    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));


                    span {display: block;}
                    span span {
                        display: inline;
                        &:first-of-type {font-size: 1.75em;}
                        @media screen and (min-width: 768px) {
                            display: inline;
                            &:first-of-type {
                                margin-right: .5rem;
                                font-size: inherit;
                            }
                        }
                    }
                    sup {
                        font-size: .5em;
                        vertical-align: .8em;
                        top: 0;
                    }
                    small {
                        font-size: .7em;
                        font-weight: 700;
                    }

                    span:first-child small {
                        font-size: .3em;
                        font-weight: 300;
                        vertical-align: 1.7em;
                    }
                    // @media screen and (min-width:991px) {
                    //     max-width: 75%;
                    //     margin: 0 auto;
                    // }
                    // @media screen and (max-width: 967px) {
                    //     max-width: 100%;
                    //     margin: 0 auto;
                    //     font-size: 2.5em;
                    // }
                }

                .title-post { 
                    font-size: 1.25em;
                    small {
                        font-weight: 300;
                        font-size: .4em;
                        vertical-align: 1em;
                    }
                }

                .plus-cash {
                    font-size: 1.6em;
                    font-family: $body-font;
                    font-style: normal;
                    font-weight: normal;
                    text-transform: none;;
                }

                .logos {
                    span { display: inline-block; }
                    span img { width: 50%; height: auto; }
                    @media screen and (max-width: 767.9px) {
                        display: flex;
                    }
                }

                margin-bottom: 2.5em;
            }

            .home-header {
                font-family: $heading-font;
                text-align: center;
                margin: 30px 0;
                text-transform: uppercase;

            }

            .home-body-text{
                font-family: $body-font;
                font-size: 1.6em;
                text-align: center;
                font-weight: normal;
                // margin: 1em 0;

                a { color: $white; }
                @media screen and (min-width:768px) {
                    max-width: 60%;
                    margin: 0 auto;
                }
            }


            .insta-link {
                width: 2em;
                height: 2em;
            }
            
        }
        &#howtoenter {
            text-align: center;
            padding: 3em 0;
           
            div {
                img {
                    // height: 150px;
                    object-fit: contain;
                    // margin-bottom: 1em;
                    &.overflow {
                        margin-top: -1em;
                        margin-bottom: 1em;
                        // height: calc(150px + 2em);
                    }
                }
            }

            h2, h3 {
                text-transform: uppercase;         
            }
            h2 {
                margin-bottom: 1em;
            }
            h3 {
                font-size: 1em;
                margin-bottom: .5em;
            }

            p {
                max-width: 500px;
                margin: 0 auto;
                font-weight: normal;
                small {
                    display: block;
                }
            }
        }

        
        &.learn-more-container {
            font-family: $body-font;
            text-align: center;
            // margin: 2em 0;
            flex-direction: column;
            small { font-size: 1.2em; font-weight: 700; }
            a {

                background-image: url(../../images/chevron.png);
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: 60px auto;
                padding-bottom: 40px;
                display: block;

                // a, a:visited, a:hover {
                    font-size: 1em;
                    color: #fff;
                    text-decoration: none;
                    text-transform: capitalize;
                // }
            }
        }
    }

    // .container.disclaimer-wrap { padding: 0px; }
    .disclaimer {

        p {
            a{
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

#summeroffers {
    color: $lightblue;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 auto;
    a {
        color: $lightblue;
        text-transform: uppercase;
        &.recipe-link {
            padding: 1rem 0;
        }
    }
   .grid {
    display: grid;
    grid-template-columns: auto;
   
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    gap: 1rem;

    >div {
        background-color: $white;
        padding: .25rem;
        border-radius: 10px;
        > a {
            padding: 0px;
        }
    }
   }
   img {
        width: 100%;
        object-fit: contain;
    }
    h3 {
        font-size: 1.5em;
        padding: 0 .5rem;
        min-height: 5rem;

        @media screen and (min-width: 1200px) {
            min-height: 3rem;
        }
        @media screen and (max-width: 990px) {
            min-height: 7rem;
        }
        @media screen and (max-width: 768px) {
            min-height: 2.5rem;

        }
    }
    a {
        font-family: 'omnes-georgian';
        font-weight: 700;
        font-size: 1.1em;
        
        display: block;

        span {
            padding: 1rem 0 1.5rem;
            display: block;
        }
        
    }
    .mobileOnly {
        > div {
            background-color: $white;
            padding: .25rem;
            border-radius: 10px;
        }
    }
    h2 {color: $white;}
}

.steps {
    font-family: $heading-font;
    font-weight: 700;
}
.homepage-legal {
    font-family: $home-title-font;
    font-size: .85em;
}

//french version
.fr-CA{
    .main-wrapper .full-Vh .home-text-container .heading-text,
    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-the-secret,
    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        

        @media screen and (max-width: 460px) {
            
        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-win{
        @media screen and (max-width: 460px) {
            
        }
    }

    .main-wrapper .full-Vh .home-text-container .enter-btn-container button p{

        width: 100%;
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        @media screen and (max-width: 460px) {

        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .prize-money{
        @media screen and (max-width: 460px) {

        }
    }
    .main-wrapper .row {
        .coming-soon-text-container, .home-text-container {
            .heading-text {
                .prize-money {
                    @media screen and (max-width: 1199px) {
                    }

                    @media screen and (max-width: 991px) {
                        
                    }

                    @media screen and (max-width: 460px) {
                    }
                }
                .plus-cash {
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 991px) {
                    }
                    @media screen and (max-width: 460px) {
                    }
                }
            }
        }
    }
}

.fullwidth {
    width: 100vw;
    margin: 0 -7.25rem;
    padding: 3rem;
}

.home .caddy {
    width: 100%;
    object-fit: contain;
    justify-self: center;
  
    @media screen and (min-width: 768px) {
        justify-self: flex-end;
    }
}
.snack-caddy {
    position: relative;
    z-index: 5;
    padding: 4rem 2rem;
    width: 100%;
    margin: 0 auto;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    text-align: center;

    .col-md-6:first-of-type {
        text-align: center;
    }
    img {
        // align-self: flex-end;
        order:2;
        margin-left: -3rem;
    }
    h2, span {order: 1;}
    a {
        order: 3;
        height: fit-content;
        margin-top: 15px;
    }  
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 1;
    }
    
    @media screen and (min-width: 990px) {
        padding: 6rem 2rem;
    }
    @media screen and (min-width: 768px) {
        > div {
            order: 2;
            grid-row: 1;
            grid-column: 2;
            
            align-items: start;
        }
        text-align: left;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 150px);
        img {order: 1;}
        h2 {
            grid-column: 2;
            order: 1;
            grid-row: 1;
            text-align: left;
            float: left;
            width: 100%;
            align-self: flex-start;
            
        }
        a {
            grid-row: 2;
            grid-column: 2;
            order: 2;
            justify-self: flex-start;
            height: fit-content;
        }
        .col-md-6:first-of-type {
            text-align: right;
        }
    }

    &:after {
        position: absolute;
        content: '';
        height: 100%;
        // width: 100vw;
        width: 100vw;
        // padding: 3rem;
        background: linear-gradient(180deg, $cyan, $purple);
        z-index: -1;
        top: 0;
        // left: -52px;
        margin: 0 calc(-113.5px + -15px);

        @media screen and (min-width: 768px) {
            // left: -16rem;
            // width: calc(100vw + 14rem);
            background: linear-gradient(120deg, $cyan, $purple);
        }
        @media screen and (min-width: 1028px) {
            // width: calc(100vw + 20rem);
            
        }
        @media screen and (min-width: 1200px) {
        //    left: -40rem;
        //    width: calc(100vw + 38rem);
            margin: 0 (3rem);
        }
    }
}