@import './variables';

body{
    font-family: sans-serif;
    font-size: 14px;
    background: $navy;

    &:has(.home), &:has(.prize-details), &:has(.thankyou) {
        background-image: url('../images/bg-2.jpg');
        background-position: center;
    }
}

.skip-to-content-link {
    position: absolute;
    transform: translateY(-100%);
    z-index: 9999;
    padding: .5em;
    font-family: $body-font;
    color: $white;
    background: $blue;

    &:focus {
        transform: translateY(0%);              
    }
}

.main-wrapper{
    padding-top: 7em;
    // padding-top: 64px;
    overflow-x: clip;
    // padding-top: 4rem;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 120px);
}
.main-wrapper .rules a {
    text-decoration: underline;
    color: $white;
}

#bg {
    width: 100%;
    position: absolute;
    z-index: 0;
    background-image: url('../images/bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    // height: 600px;

    // &:after {
    //     position: absolute;
    //     content: '';
    //     background-image: url('../images/pucks.png');
    //     height: 75%;
    //     width: 50%;
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: center;
    //     bottom: 20%;
    //     left: 50%;
    //     transform: translateX(-50%);
    // }
    &:before {
        position: absolute;
        content: '';
        bottom: -1px;
        width: 100%;
        height: 300px;
        background: linear-gradient(0deg, #001271 0%, transparent 75%);
    }
    &:has(~ .prize-details),
    &:has(~ .faq),
    &:has(~ .rules) {
        display: none;
    }
    &:has(~ .bg-left),
    &:has(~ .you-lose) {
        background-size: cover;
        position: absolute;
        background-position: center;
        &:after {
            left: -5rem;
            transform: none;
            top: 50%;
            height: 50%;
        }
        > div {
            // position: relative;
            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $navy;
                opacity: .7;
                z-index: 5;
                bottom: -1px;
                content: '';
            }
        }
    }

    height: 600px;

    // @media screen and (max-width: 1024px) {
    //     display: none;
   
    //     &:has( ~ .main-wrapper > .home) {
    //         display: block;
    //     }
    //     &:before {
    //         height: 150px;
    //     }
    //     &:after {
    //         bottom: 18%;
    //     }
    // }
    // @media screen and (max-width: 768px) {
    //     height: 600px;
        
    //     &:after {
    //         width: 75%;
    //         height: 65%;
    //     }
    // }
    // @media screen and (max-height: 700px) {
    //     min-height: 400px;
    // }
    // @media screen and (min-width: 1500px) {
        
    //     &:after {
    //         bottom: 20%;
    //     }
    // }
}
.main-wrapper .info-form__container .info-form .MuiFormControlLabel-root > span:not(.MuiTypography-body1) span:focus-within {
outline: none;
}
.main-wrapper .info-form__container .MuiFormLabel-root,
.enter-btn, 
.main-wrapper form .MuiFilledInput-input {
    font-family: $bold-font;
}
.main-wrapper form .MuiInputBase-input,
.main-wrapper form .MuiInputBase-input::placeholder,
.main-wrapper form .MuiFormLabel-root,
.main-wrapper .MuiMenuItem-root.MuiListItem-root
 {
    font-family: $body-font !important;
    font-size: 1.2em;
    color: $white;
}
.main-wrapper .info-form__container .info-form .MuiTextField-root .MuiInputBase-root,
.main-wrapper .MuiFilledInput-root {
    background: rgba(63,152,255,55%);
    border: 4px solid #3F98FF;
    border-radius: 10px;
}
.main-wrapper .MuiSvgIcon-root {
    fill: $white;
    font-size: 3.5em;
    top: 0;
}

.main-wrapper .info-form__container .MuiFormLabel-root {
    font-size: 18px;
    color: $white;
    font-weight: normal;
}

.main-wrapper form .MuiFilledInput-root .MuiInputBase-input.MuiFilledInput-input {
    color: $white;
}

.main-wrapper .info-form__container .info-form .MuiTextField-root {
    margin-bottom: 1.5rem;
}
.you-win form {
    margin-top: 40px;
    
    .MuiFilledInput-root {
        background: rgba(63,152,255,55%);
        border: 4px solid #3F98FF;
        font-size: 1.2em;
        border-radius: 8px;
        input {
            box-shadow: none;
            border: none;
        }
       
    } 
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(12px, 1rem) scale(1);
        transform-origin: center;
    }
} 
.justify-center {justify-content: center;}
.enter-btn {
    border-radius: 3em;
    line-height: 2em;
    width: 300px;
    font-weight: 700;
    font-size: 2em;
    display: inline-block;
    color: $navy;
    box-sizing: border-box;
    position: relative;
    transition: background-color .3s ease;
}

.enter-btn {
    background: $white;
    text-align: center;
    // background-clip: padding-box;
    border: solid 2px transparent;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0; right: 0; bottom: 0; left: 0;
    //     z-index: -1;
    //     margin: -2px;
    //     border-radius: inherit;
    //     background: linear-gradient(to right, $cyan, $purple);
    // }
    
    &:hover {
        color: $blue;
        // background-color: #00000000;
        text-decoration: none;
        // -webkit-filter: drop-shadow(0px 0px 6px $white);
        // filter: drop-shadow(0px 0px 6px $white);
    }
    &:focus {
        outline: 2px #fff;
    }

    text-transform: uppercase;

    &[disabled] {
        background-color: #65656565;
        pointer-events: none;
    }
    &.info-form__submit-btn {
        display: block;
        margin: 0 auto;
    }
}

.main-wrapper .pin-form__container img {
    height: 100%;
    object-fit: fill;
}

.fr-CA {
    .enter-btn {
        font-size: 1em;
        line-height: 4em;
    }
    .snack-caddy {
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 50%);
        grid-column-gap: 2rem;
        grid-row-gap: 0px;
    }
    .main-wrapper {
        .text-xxl {
            font-size: 2em;
            @media screen and (min-width: 1200px) {
                font-size: 2.5em;
            }
        }
    }
    @media screen and (max-width: 764px) {
        .snack-caddy {
            grid-template-columns: auto;
            gap: 2rem;
            justify-content: center;

            img {
                margin-left: -5.5rem;
            }
        }
    }
}

.slick-next:before,
.slick-prev:before {
    content: '';
}

.slick-dots li button:before {
    line-height: normal;
    font-size: 20px;
    color: transparent;
    border: 2px solid white;
    border-radius: 50%;
    opacity: 1;
    margin: 2rem 0;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: transparent;
    background-color: white;
    border: 3px solid transparent;
}
.mobileOnly {
    display: none;
}
.noMobile {
    display: block;
}
#howtoenter .noMobile {
    display: inline-flex !important;

}
#howtoenter {
    justify-content: center;
    
    .steps {
        display: flex;
        text-align: center;
        margin-bottom: 8rem;

        img {
            // height: 100%;
            margin-bottom: 1em;
        }

        // .slick-dots {
        //     bottom: 0;
        // }
    }
}


.pin-form__description {
    small {
        line-height: 2;
        margin-bottom: 2rem;
        font-family: $heading-font;
    }
}


.pucks-container {
    position: relative;
    padding-bottom: 100%;
    img {
        width: 44%;
        position: absolute;
        margin-left: -22%;
        margin-top: -22%;

        &#oreo {
            left: 50%;
            top: 22%;
        }
        &#puck1 {
            left: 25%;
            top: 48%;
        }
        &#puck2 {
            left: 85%;
            top:54%;
        }
        &#puck3 {
            left: 57%;
            top: 79%;
        }

        @media screen and (min-width: 768px) {
            width: 38%;
            margin-left: -19%;
            margin-right: -19%;
        }
    }
}
.thankyou .pucks-container {
    padding-bottom: 60%;
    margin: 0 -1.2em 6em -1.2em;
    img {
        @media screen and (max-width: 768px) {
            width: 50%;
            margin-left: -25%;
            margin-top: -25%;
        }

        &#puck1 {
            left: 25%;
            top: 65%;
            @media screen and (max-width: 768px) {
                top: 45%;
            }
        }
        &#puck2 {
            left: 85%;
            top:48%;
        }
        &#puck3 {
            left: 52%;
            top: 95%;
        }
    }
}
.thankyou .you-win {
    font-size: 1.2em;

    p:last-child {
        margin-top: 6em;
    }
}



@media screen and (max-width: 768px) {
    .mobileOnly {display: block;}
    .noMobile {display: none !important;}


    .main-wrapper .home .row#howtoenter {
        // padding: 2rem 0;
        padding-bottom: 7rem;
        .steps {
            padding:0px;
            margin-bottom: 0px;
            div {
                margin-bottom: 0px;

                // img {margin: 0 auto !important;}
            }
            p {
                padding: 0 2rem;
            }
        }
    } 
}