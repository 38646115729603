// @import url("https://use.typekit.net/llp1prw.css");

@font-face {
    font-family: 'Helvetica';
    src: url('/fonts/helveticaneue-01-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('/fonts/helveticaneue-condensedblack-10-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Logik-Regular';
    src: url('/fonts/logik_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Logik-Bold';
    src: url('/fonts/logik_black-webfont.woff') format('woff');
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: 'Pluto-Black';
    src: url('/fonts/Pluto_Oreo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Pluto';
    src: url('/fonts/hvdtrial_pluto-regular.woff') format('woff'), url('/fonts/hvdtrial_pluto-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Pluto';
    src: url('/fonts/hvdtrial_pluto-bold.woff') format('woff'), url('/fonts/hvdtrial_pluto-bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}