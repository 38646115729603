@import './fonts.scss';

$grey : #00000029;
$textgrey: #707070;
$text-dark: #010F28;
$white : #ffffff;
$navopen: #001860;
$black: #000000;
$darkgrey: #010F28;
$darkblue: #304EC8;
$blue: #0058C9;
$navy: #001271;
$lightblue: #3B87BF;
$cyan: #0CB2EF;
$purple: #5D20C0;
$darkpurple: #6134DD;
$yellow: #FFEB00;
$gold: #FFC400;
$yellow-gold: transparent linear-gradient(111deg, #FFC400 0%, #FFEB00 100%) 0% 0% no-repeat padding-box;
$blue-purple: transparent linear-gradient(111deg, #304EC8 0%, #6134DD 100%) 0% 0% no-repeat padding-box;

//fonts
$heading-font : "Pluto-Black", sans-serif;
$home-title-font : "Helvetica", sans-serif;
$body-font : "Pluto", sans-serif;
$bold-font : "Pluto-Black", sans-serif;
