@import '../../styles/variables';
@import '../../styles/fonts';

.fr-CA + footer .site-footer{
    .footer-links {
        @media screen and (max-width: 1023px){
            li {
                flex: 1;
            }
        }
    }
}

.site-footer{
    //padding: 30px 0;
    background: $navy;
    // height: 120px;
    // padding: 3rem 3rem 5rem 3rem;
    display: flex;
    align-items: center;
    padding-bottom: 1em;

    @media screen and (max-width: 460px){
        height: unset;
        // padding: 20px;
    }

    .footer-links{
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin: 2rem 0;
        text-align: center;

        @media screen and (max-width: 460px){
            // font-size: 12px;
            // flex-direction: column;
        }

        li {
            text-align: center;
            @media screen and (min-width: 768px){
                display: inline-block;
            }
            line-height: 2em;
            height: 2em;
            padding-left: 1em; padding-right: 1em;

            a {
                vertical-align: middle;
                text-decoration: underline;
                line-height: 1em;
                color: $white;
                font-size: .85em;
            }
        }

        a{
            color: inherit;
            text-transform: initial;
        }
    }

    .mondelez-logo{
        margin-bottom: 1rem;
        .mondelez-logo-container {
            max-height: 25px;
            display: flex;
            justify-content: center;

            img{
                max-height: 25px;
            }

            @media screen and (max-width: 767.9px) {
                max-height: 50px;
                img {
                    max-height: 50px;
                }
               
            }
        }

        & + .row {
            margin-bottom: 2em;
        }
    }

}

footer .logo{
    align-items:center;
    justify-content: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1rem auto;
    > span {
        display: block;
        flex: 1;
        span {
            display: inline-block;
            img {
                width: 50%;
                margin: 0 auto;
            }
        }
    }


    @media screen and (max-width: 767.9px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        text-align: center;
        grid-row-gap: 0px;
        grid-column-gap: 1.25rem;

        > span {
            span img {
                width: 75%;
            }
            &:nth-child(5) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
                grid-area: 2/2;
            }
            &:nth-child(6) {
                -ms-grid-row: 2;
            -ms-grid-column: 3;
            grid-area: 2/3;
            }
        }
    }
    @media screen and (min-width: 576px) and (max-width: 767.9px) {
        width: 540px;
        
    }
}

.footer-legal p {
    color: $white;
    font-size: 0.85em;
    margin-top: 1em;
}